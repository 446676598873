// Select2
// -----------------------------------
import $ from 'jquery';
// Select2
import 'select2';
// import 'select2/dist/css/select2.min.css';
// import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
$("#select_all_payout").click(function() {
    if ($("#select_all_payout").is(':checked')) {
        $("#payout_share_ids > option").prop("selected", "selected"); // Select All Options
        $("#payout_share_ids").trigger("change"); // Trigger change to select 2
    } else {
        $("#payout_share_ids > option").removeAttr("selected");
        $("#payout_share_ids").trigger("change"); // Trigger change to select 2
    }
});

function initSelect2() {
    if (!$.fn.select2) return;
    // Select 2
    $('.select2payment').select2({
        placeholder: 'subtract payments',
        theme: 'bootstrap4',
        multiple: true
    });
    $('#tag_hotspot_ids').select2({
        placeholder: 'Select hotspots',
        theme: 'bootstrap4',
        multiple: true
    });
    $('#tag_contact_ids').select2({
        placeholder: 'Select Contacts',
        theme: 'bootstrap4',
        multiple: true
    });
    $('#share_hotspot_id').select2({
        placeholder: 'Select a hotspot',
        theme: 'bootstrap4'
    });
    $('#share_hotspot_ids').select2({
        placeholder: 'Select hotspots',
        theme: 'bootstrap4',
        multiple: true
    });
    $('#user_currency').select2({
        theme: 'bootstrap4'
    });
    $('#contact_currency').select2({
        theme: 'bootstrap4'
    });
    $('#user_time_zone').select2({
        theme: 'bootstrap4'
    });
    $('#payout_time_zone').select2({
        theme: 'bootstrap4'
    });
    $('#monthly_report_time_zone').select2({
        theme: 'bootstrap4'
    });
    $('#share_contact_id').select2({
        placeholder: 'Select a contact',
        theme: 'bootstrap4'
    });
    $('#payout_share_ids').select2({
        theme: 'bootstrap4',
        multiple: true
    });
    $('#site_features').select2({
        theme: 'bootstrap4',
        multiple: true
    });
    $('#site_disabled_features').select2({
        theme: 'bootstrap4',
        multiple: true
    });
    $('#select2-4').select2({
        placeholder: 'Select a state',
        allowClear: true,
        theme: 'bootstrap4'
    });
}
export default initSelect2