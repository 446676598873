import consumer from "./consumer"



$(document).on('ready', function () {


var own_ids = $('#own_wallets').attr('data-ids').split(",");


$.each( own_ids, function( index, value ){

  consumer.subscriptions.create( {
    channel: "HotspotChannel",   wallet_id: value,
  },
  {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      
      // Called when there's incoming data on the websocket for this channel
    if(data.notification)
    {
    $.notify(data.notification, { showDuration: 15000, autoHideDelay: 15000 });
    
    }

    if(data.update == "hotspots" && $("#hotspots").length > 0)
    {
      $("#hotspots").load("/hotspots");
    }

    }
  });


  })



});


