// DATATABLES
// -----------------------------------
import $ from 'jquery';
// Libraries for buttons
//import * as JSZip from "jszip";
//window.JSZip = JSZip;
//require('pdfmake/build/pdfmake.js');
//require('pdfmake/build/vfs_fonts.js');
// Datatables
require('datatables.net-bs4/js/dataTables.bootstrap4.js')
require('datatables.net-bs4/css/dataTables.bootstrap4.css')
//require('datatables.net-buttons/js/dataTables.buttons.js')
require('datatables.net-buttons-bs/js/buttons.bootstrap.js')
require('datatables.net-buttons-bs/css/buttons.bootstrap.css')
//require('datatables.net-responsive')
require('datatables.net-responsive-bs')
require('datatables.net-responsive-bs/css/responsive.bootstrap.css')
require('datatables.net-keytable');
require('datatables.net-keytable-bs/css/keyTable.bootstrap.css')

function handleAjaxErrorLoc(xhr, textStatus, error) {
    // do handling of your interest
}

function initDatatables() {
    if (!$.fn.DataTable) return;
    // Zero configuration
    $('#datatable1').DataTable({
        'paging': true, // Table pagination
        'ordering': true, // Column ordering
        'info': true, // Bottom left status text
        'pageLength': 100,
        "order": [
            [0, "desc"]
        ],
        responsive: true,
        // Text translation options
        // Note the required keywords between underscores (e.g _MENU_)
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        oLanguage: {
            sSearch: '<em class="fas fa-search"></em>',
            sLengthMenu: '_MENU_ records per page',
            info: 'Showing page _PAGE_ of _PAGES_',
            limit: 100,
            zeroRecords: 'Nothing found - sorry',
            infoEmpty: 'No records available',
            infoFiltered: '(filtered from _MAX_ total records)',
            oPaginate: {
                sNext: '<em class="fa fa-caret-right"></em>',
                sPrevious: '<em class="fa fa-caret-left"></em>'
            }
        }
    });
    $('#users-datatable').DataTable({
        'pageLength': 50,
        "processing": true,
        "serverSide": true,
        "order": [
            [0, "desc"]
        ],
        "ajax": {
            "url": $('#users-datatable').data('source'),
            "error": handleAjaxErrorLoc // this sets up jQuery to give me errors
        },
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        "pagingType": "full_numbers",
        "columns": [{
            "data": "id"
        }, {
            "data": "email"
        }, {
            "data": "source"
        }, {
            "data": "plan"
        }, {
            "data": "premium"
        }, {
            "data": "hotspots"
        }, {
            "data": "mailings"
        }, {
            "data": "login"
        }]
    });
    var dashTable = $('#hotspots-datatable').DataTable({
        'pageLength': 5,
        "processing": true,
        "serverSide": true,
        "stateSave": true,
        "stateDuration": 60 * 60 * 24,
        "lengthMenu": [5, 10, 25, 50],
        "order": [
            [1, "desc"]
        ],
        "ajax": {
            "url": $('#hotspots-datatable').data('source'),
            "error": handleAjaxErrorLoc // this sets up jQuery to give me errors
        },
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
            setTimeout(function() {
                var elemCount = dashTable.column(0).data().length;
                if (elemCount == 0) {
                    dashTable.ajax.reload(null, true);
                }
            }, 1000);
        },
        "pagingType": "full_numbers",
        "columns": [{
            "data": "name"
        }, {
            "data": "rolling24"
        }, {
            "data": "last_poc_challenge"
        }]
    });
    setInterval(function() {
        dashTable.ajax.reload(null, false);
    }, 300000);
    var table = $('#user-hotspots-datatable').DataTable({
        'pageLength': 25,
        "processing": true,
        "serverSide": true,
        "stateSave": true,
        "stateDuration": 60 * 60 * 24 * 7,
        responsive: true,
        //"lengthMenu": [5, 10, 25, 50],
        "order": [
            [1, "desc"]
        ],
        "ajax": {
            "url": $('#user-hotspots-datatable').data('source'),
            "error": handleAjaxErrorLoc // this sets up jQuery to give me errors
        },
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
            setTimeout(function() {
                $(document).trigger("activity.ready");
            }, 1500)
        },
        "pagingType": "full_numbers",
        "columns": [{
            "data": "rank"
        }, {
            "data": "name"
        }, {
            "data": "tags"
        }, {
            "data": "yesterday"
        }, {
            "data": "two_days"
        }, {
            "data": "week"
        }, {
            "data": "month"
        }, {
            "data": "average"
        }, {
            "data": "heartbeat"
        }, {
            "data": "options"
        }]
    });
    setInterval(function() {
        table.ajax.reload(null, false);
    }, 300000);
    $('#datatabledash').DataTable({
        'paging': true, // Table pagination
        'ordering': true, // Column ordering
        'pageLength': 5,
        "lengthMenu": [5, 10, 25, 50],
        'searching': true,
        'lengthChange': true,
        'info': false, // Bottom left status text
        "order": [
            [1, "desc"]
        ],
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        responsive: true,
        // Text translation options
        // Note the required keywords between underscores (e.g _MENU_)
        oLanguage: {
            sSearch: 'Search all columns:',
            sLengthMenu: '_MENU_ records per page',
            info: 'Showing page _PAGE_ of _PAGES_',
            zeroRecords: 'Nothing found - sorry',
            infoEmpty: 'No records available',
            infoFiltered: '(filtered from _MAX_ total records)',
            oPaginate: {
                sNext: '<em class="fa fa-caret-right"></em>',
                sPrevious: '<em class="fa fa-caret-left"></em>'
            }
        },
        // Datatable key setup
        keys: true
    });
    $('#datatable4').DataTable({
        'paging': true, // Table pagination
        'ordering': true, // Column ordering
        'searching': true,
        "order": [
            [3, "desc"]
        ],
        responsive: true,
        // Text translation options
        // Note the required keywords between underscores (e.g _MENU_)
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        oLanguage: {
            sSearch: 'Search all columns:',
            sLengthMenu: '_MENU_ records per page',
            info: 'Showing page _PAGE_ of _PAGES_',
            zeroRecords: 'Nothing found - sorry',
            infoEmpty: 'No records available',
            infoFiltered: '(filtered from _MAX_ total records)',
            oPaginate: {
                sNext: '<em class="fa fa-caret-right"></em>',
                sPrevious: '<em class="fa fa-caret-left"></em>'
            }
        },
        // Datatable key setup
        keys: true
    });
    $('#datatablenoorder').DataTable({
        'paging': true, // Table pagination
        'ordering': true, // Column ordering
        'searching': true,
        "order": [],
        responsive: true,
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        // Text translation options
        // Note the required keywords between underscores (e.g _MENU_)
        oLanguage: {
            sSearch: 'Search all columns:',
            sLengthMenu: '_MENU_ records per page',
            info: 'Showing page _PAGE_ of _PAGES_',
            zeroRecords: 'Nothing found - sorry',
            infoEmpty: 'No records available',
            infoFiltered: '(filtered from _MAX_ total records)',
            oPaginate: {
                sNext: '<em class="fa fa-caret-right"></em>',
                sPrevious: '<em class="fa fa-caret-left"></em>'
            }
        },
        // Datatable key setup
        keys: true
    });
    $('a.toggle-vis').on('click', function(e) {
        e.preventDefault();
        // Get the column API object
        var column = table.column($(this).attr('data-column'));
        // Toggle the visibility
        column.visible(!column.visible());
    });
    $('#datatable3').DataTable({
        'paging': true, // Table pagination
        'ordering': true, // Column ordering
        'pageLength': 100,
        'info': true, // Bottom left status text
        responsive: true,
        // Text translation options
        // Note the required keywords between underscores (e.g _MENU_)
        "drawCallback": function() {
            $('.dataTables_paginate a').addClass('btn btn-sm btn-dark');
        },
        oLanguage: {
            sSearch: 'Search all columns:',
            sLengthMenu: '_MENU_ records per page',
            info: 'Showing page _PAGE_ of _PAGES_',
            zeroRecords: 'Nothing found - sorry',
            infoEmpty: 'No records available',
            infoFiltered: '(filtered from _MAX_ total records)',
            oPaginate: {
                sNext: '<em class="fa fa-caret-right"></em>',
                sPrevious: '<em class="fa fa-caret-left"></em>'
            }
        },
        // Datatable key setup
        keys: true
    });
}
export default initDatatables;