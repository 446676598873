import consumer from "./consumer"
window.last_sound_time = 0;
$(document).ready(function() {
    var own_ids = $('#own_wallets').attr('data-ids');
    if (own_ids != undefined) {
        consumer.subscriptions.create({
            channel: "UserChannel",
            user_id: own_ids,
        }, {
            connected() {
                // Called when the subscription is ready for use on the server
            },
            disconnected() {
                // Called when the subscription has been terminated by the server
            },
            received(data) {
                // Called when there's incoming data on the websocket for this channel
                console.log(data);
                if (data.enable) {
                    $(data.enable).attr('disabled', false);
                }
                if (data.notification) {
                    $.notify(data.notification, {
                        showDuration: 15000,
                        autoHideDelay: 15000,
                        position: "top-right",
                        group: "beacon"
                    });
                }
                if (data.replace) {
                    $(data.replace).replaceWith(data.content);
                } else {
                    if (data.time > window.last_sound_time + 10) {
                        window.last_sound_time = data.time;
                        if (data.update == "realtime") {} else {
                            if (data.update == "ping") {
                                if (document.getElementById(data.sound)) {
                                    document.getElementById(data.sound).muted = false;
                                    document.getElementById(data.sound).play();
                                }
                            } else {
                                if (data.amount) {
                                    //document.getElementById("cash-audio").volume = 10;
                                    if (data.sound && data.amount > 0) {
                                        if (document.getElementById(data.sound)) {
                                            document.getElementById(data.sound).muted = false;
                                            document.getElementById(data.sound).play();
                                        }
                                    } else {
                                        if (document.getElementById("cash-audio")) {
                                            document.getElementById("cash-audio").muted = false;
                                            document.getElementById("cash-audio").play();
                                        }
                                    }
                                } else {
                                    // document.getElementById("readio-beep").volume = 1;
                                    if (document.getElementById("radio-beep")) {
                                        document.getElementById("radio-beep").muted = false;
                                        document.getElementById("radio-beep").play();
                                    }
                                }
                            }
                        }
                    }
                    $("#ping_button").show();
                    if (data.update == "premium_payment_amount") {
                        var amount = $("#premium_payment_amount").data("amount");
                        var new_amount = parseFloat(amount * 1.0 + data.amount).toFixed(2);;
                        $("#premium_payment_amount").text(new_amount);
                        $("#premium_payment_amount").data("amount", new_amount);
                    }
                    if (data.update == "hotspots" && $("#hotspots").length > 0) {
                        $("#hotspots").load("/hotspots");
                    }
                    if (data.update == "dashboard" && $("#main_dashboard").length > 0) {
                        $("#dashboard").load("/dashboard/activities.html", function(response, status, xhr) {
                            $(document).trigger("activity.ready");
                        });
                    }
                    if (data.update == "dashboard_rewards" && $("#main_dashboard").length > 0) {
                        $("#dashboard").load("/dashboard/activities.html", function(response, status, xhr) {
                            $(document).trigger("activity.ready");
                            $(document).trigger("reward.ready");
                        });
                        $.get("/dashboard/today.html", function(data) {
                            $("#reward_today").replaceWith(data);
                            $("#reward_today").effect("highlight", {}, 3000);
                        });
                    }
                    if (data.update == "realtime") {
                        $("#realtime_result").append("<p><strong>" + data.result.title + ":</strong><span class='" + data.result.color + "'> " + data.result.subject + "</span></p>");
                        console.log(data.result);
                        if (data.result.title == "Online Status") {
                            $("#realtime_button").show();
                            $("#realtime_indicator").hide();
                        }
                    }
                }
            }
        });
    }
});