// LOAD CUSTOM CSS
// -----------------------------------

import $ from 'jquery';
import Cookies from 'js-cookie'


function initCSS() {


    if(Cookies.get('layout') == 'theme-a')
    {
        
        $("#moon").show();
        $("#sun").hide();
        
    }
    else
    {
        $("#moon").hide();
        $("#sun").show();
    }

    $('[data-load-css]').each(function() {

        var element = $(this);


        var uri = element.data('loadCss'),
            link;

         if (uri) {
                if(uri.includes("theme-e") && Cookies.get('layout') == 'theme-e')
                {
                    link = createLink(uri);
                    $( "table.table" ).addClass( "table-dark" );
                }
            }


    });
}

function initLoadCSS() {



    $('[data-load-css]').on('click', function(e) {

        var element = $(this);

        if (element.is('a'))
            e.preventDefault();

        var uri = element.data('loadCss'),
            link;

        if(uri.includes("theme-e"))
        {
            Cookies.set('layout', 'theme-e')
            $( "table.table" ).addClass( "table-dark" );

            $.get( "account/switch_layout?layout=dark", function( data ) {
            });

            $("#moon").hide();
            $("#sun").show();
        }
        else
        {
            Cookies.set('layout', 'theme-a')
            $( "table.table-dark" ).removeClass( "table-dark" );
            $("#themcss").remove();
            $.get( "account/switch_layout?layout=light", function( data ) {
            });
            $("#moon").show();
        $("#sun").hide();
        }

        if (uri) {
            link = createLink(uri);
            if (!link) {
                $.error('Error creating stylesheet link element.');
            }
        } else {
            $.error('No stylesheet location defined.');
        }

    });
}

function createLink(uri) {
    var linkId = 'autoloaded-stylesheet',
        oldLink = $('#' + linkId).attr('id', linkId + '-old');

    $('head').append($('<link/>').attr({
        'id': linkId,
        'rel': 'stylesheet',
        'href': uri
    }));

    if (oldLink.length) {
        // delay removal of previous stylesheet to avoid some flickering
        setTimeout(function() {
            oldLink.remove();
    	}, 300);
    }

    return $('#' + linkId);
}



export { initLoadCSS, initCSS };
