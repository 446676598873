// CHART SPLINE
// -----------------------------------
import $ from 'jquery';
// Flot Charts
function initPayment() {
    if ($("#payout").length == 1) {
        setInterval(loadPayments, 7000);
    }
}

function initPremiumPayment() {
    if ($("#premium_payment").length == 1) {
        setInterval(loadPremiumPayments, 20000);
    }
}

function initQrcode() {
    $('.qrcode').each(function() {
        var json = "";
        if ($(this).data("address")) {
            if ($(this).data("amount")) {
                // https://wallet.helium.com/payment?payee=D2m1gv3xFrQBWPrV65aexznx5XGiiyWV79Piy4io2VeW&amount=190000000
                json = JSON.stringify({
                    "type": "payment",
                    "address": $(this).data("address"),
                    "amount": $(this).data("amount") + "",
                    "memo": $(this).data("memo").toString()
                });
            } else {
                json = JSON.stringify({
                    "type": "payment",
                    "address": $(this).data("address")
                });
            }
        } else {
            if ($(this).data("addresses")) {
                // payees: {[payeeAddress]: { amount, memo? }}
                var addresses = $(this).data("addresses").trim().split(" ");
                var amounts = $(this).data("amounts").trim().split(" ");
                var memos = ""; //$(this).data("memos").trim().split(" ");
                var payess = {};
                for (let i = 0; i < addresses.length; i++) {
                    //var memo = memos[i];
                    var amount = amounts[i] * 10 ** 8;
                    payess[addresses[i]] = {
                        "amount": amount * 10 ** 8
                        //,
                        //memo
                    };
                }
                json = JSON.stringify({
                    "type": "payment",
                    "payees": payess
                });
                console.log(json);
            } else {
                json = $(this).data("url");
            }
        }
        $(this).kjua({
            text: json,
            mSize: 21,
            size: 350,
            fontcolor: "#ff0000",
            mode: 'plain',
            fontname: "Sans",
            fontcolor: "#3b8aDa",
            label: $(this).data("label")
        });
    });
    $('.qrcodesm').each(function() {
        var json = "";
        if ($(this).data("address")) {
            // https://wallet.helium.com/payment?payee=D2m1gv3xFrQBWPrV65aexznx5XGiiyWV79Piy4io2VeW&amount=190000000
            json = "https://wallet.helium.com/payment?payee=" + $(this).data("address") + "&amount=" + ($(this).data("amount") * 10 ** 8);
            /*if ($(this).data("amount")) {
                json = JSON.stringify({
                    "type": "payment",
                    "address": $(this).data("address"),
                    "amount": $(this).data("amount") + "",
                    "memo": $(this).data("memo").toString()
                });
            } else {
                json = JSON.stringify({
                    "type": "payment",
                    "address": $(this).data("address")
                });
            }*/
        } else {
            if ($(this).data("addresses")) {
                // payees: {[payeeAddress]: { amount, memo? }}
                var addresses = $(this).data("addresses").trim().split(" ");
                var amounts = $(this).data("amounts").trim().split(" ");
                var memos = $(this).data("memos").trim().split(" ");
                var payess = {};
                for (let i = 0; i < addresses.length; i++) {
                    var memo = memos[i] * 10 ** 8;
                    var amount = amounts[i];
                    payess[addresses[i]] = {
                        amount,
                        memo
                    };
                }
                json = JSON.stringify({
                    "type": "payment",
                    "payees": payess
                });
                console.log(json);
            } else {
                json = $(this).data("url");
            }
        }
        $(this).kjua({
            text: json,
            mSize: 21,
            size: 200,
            fontcolor: "#ff0000",
            mode: 'plain',
            fontname: "Sans",
            fontcolor: "#3b8aDa",
            label: $(this).data("label")
        });
    });
}

function changePlans() {
    var Selector = '[data-plan]';
    $(Selector).each(function() {
        var $this = $(this);
        var plan = $this.data("plan");
        var usd = $this.data("price") * 1.0;
        var hnt = $this.data("hnt") * 1.0;
        var user = $this.data("user") * 1.0;
        $this.on('change', function(e) {
            var discount = 1.0;
            var memo;
            var months = $this.val() * 1.0;
            if (months == 3) {
                discount = 0.9;
            }
            if (months == 6) {
                discount = 0.8;
            }
            if (months == 12) {
                discount = 0.7;
            }
            var extraDiscount = $("#premium_payment").data("discount") * 1 / 100;
            var totalHnt = hnt * discount * months * extraDiscount;
            $("#hnt_price_" + plan).text(Number.parseFloat(hnt * discount).toPrecision(2));
            $("#usd_price_" + plan).text(Number.parseFloat(usd * discount).toPrecision(3));
            if ($("#premium_payment").length == 1) {
                memo = plan[0] + Math.round((months / 3)) + user;
                if (plan == "single") memo = "n" + Math.round((months / 3)) + user;
                if (plan == "enterprisex") memo = "x" + Math.round((months / 3)) + user;
                if (plan == "enterprisexl") memo = "y" + Math.round((months / 3)) + user;
                $("#premium_payment").html("");
                $("#premium_payment").data("amount", totalHnt);
                $("#plan_months").html(months);
                $("#premium_payment").data("memo", memo);
                $("#memo").html(memo);
                $("#payout_last").data("memo", memo);
                $("#total_amount").text(Number.parseFloat(totalHnt).toPrecision(3))
                initQrcode();
            }
        });
    });
}

function loadPayments() {
    $(function() {
        $.getJSON("/dashboard/pending_payments", function(json) {
            json.forEach(function(item) {
                var amount = item.amount;
                var payee = item.payee;
                var share = item.share;
                $('.payout[data-payee="' + payee + '"]').each(function(index) {
                    console.log($(this).data("amount"));
                    $(this).addClass("paid");
                    $(this).next(".card-body").html("paid");
                });
            });
            // console.log(json);
        });
    });
}

function loadPremiumPayments() {
    $(function() {
        $.getJSON("/dashboard/premium_payments", function(json) {
            json.forEach(function(item) {
                var amount = item.amount;
                var payee = item.payee;
                var share = item.share;
                var memo = item.memo;
                $('.payout[data-memo="' + memo + '"]').each(function(index) {
                    console.log($(this).data("amount"));
                    $(this).addClass("paid");
                    $(this).next(".card-body").html("paid");
                });
            });
            console.log(json);
        });
    });
}
export {
    initPayment,
    initPremiumPayment,
    initQrcode,
    changePlans
};