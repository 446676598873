import $ from 'jquery';
const h3 = require("h3-js");
const l = require("leaflet/dist/leaflet.js");
const meas = require("leaflet-measure/dist/leaflet-measure.js");
import ("leaflet/dist/leaflet.css");
import ("leaflet-measure/dist/leaflet-measure.css");
const m = require("leaflet.motion/dist/leaflet.motion.js");
L.TileLayer.Grayscale = L.TileLayer.extend({
    options: {
        quotaRed: 21,
        quotaGreen: 71,
        quotaBlue: 8,
        quotaDividerTune: 0,
        quotaDivider: function() {
            return this.quotaRed + this.quotaGreen + this.quotaBlue + this.quotaDividerTune;
        }
    },
    initialize: function(url, options) {
        options = options || {}
        options.crossOrigin = true;
        L.TileLayer.prototype.initialize.call(this, url, options);
        this.on('tileload', function(e) {
            this._makeGrayscale(e.tile);
        });
    },
    _createTile: function() {
        var tile = L.TileLayer.prototype._createTile.call(this);
        tile.crossOrigin = "Anonymous";
        return tile;
    },
    _makeGrayscale: function(img) {
        if (img.getAttribute('data-grayscaled')) return;
        img.crossOrigin = '';
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var imgd = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var pix = imgd.data;
        for (var i = 0, n = pix.length; i < n; i += 4) {
            pix[i] = pix[i + 1] = pix[i + 2] = (this.options.quotaRed * pix[i] + this.options.quotaGreen * pix[i + 1] + this.options.quotaBlue * pix[i + 2]) / this.options.quotaDivider();
        }
        ctx.putImageData(imgd, 0, 0);
        img.setAttribute('data-grayscaled', true);
        img.src = canvas.toDataURL();
    }
});
L.tileLayer.grayscale = function(url, options) {
    return new L.TileLayer.Grayscale(url, options);
};
const towerYellow = L.divIcon({
    html: '<i class="fa fa-circle  text-yellow"></i>',
    iconSize: [15, 15],
    className: 'myDivIcon'
});
//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}
// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}
export default class HotspotMap {
    constructor(mapid, hotspot_id, lat, lon, name = "") {
        this.map = L.map(mapid);
        this.lon = lon;
        this.lat = lat;
        this.name = name;
        this.hotspot_id = hotspot_id;
        this.hotspots = Array();
        this.witnesses = Array();
        this.witnessMarkers = Array();
        this.hexes = Array();
        this.activeHex = undefined;
        this.numbersLayer = new L.FeatureGroup();
        this.hexLayer = new L.FeatureGroup();
        this.highlightLayer = new L.FeatureGroup();
        this.highlightHexes = Array();
        var measureOptions = {}
        if ($("#" + mapid).data("metric") == true) {
            measureOptions = {
                primaryLengthUnit: 'meters',
                secondaryLengthUnit: 'kilometers'
            };
        }
        this.measureControl = new L.control.measure(measureOptions);
    }
    init() {
        this.map.setView([this.lat, this.lon], 13);
        L.tileLayer.grayscale('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            className: 'map-tiles'
        }).addTo(this.map);
        var data = {
            id: this.hotspot_id,
            name: this.name,
            lon: this.lon,
            lat: this.lat
        }
        var me = this;
        this.addHotspot(data);
        this.map.on('dragend', function(e) {
            if (me.map.getZoom() > 9) {
                me.loadHexes();
            }
        });
        this.map.on('zoomend', function(e) {
            if (me.map.getZoom() > 9) {
                me.loadHexes();
            }
            if (me.map.getZoom() < 12) {
                if (me.map.hasLayer(me.numbersLayer)) {
                    me.map.removeLayer(me.numbersLayer);
                }
            } else {
                me.map.addLayer(me.numbersLayer);
            }
            if (me.map.getZoom() < 10) {
                if (me.map.hasLayer(me.hexLayer)) {
                    me.map.removeLayer(me.hexLayer);
                }
            } else {
                me.map.addLayer(me.hexLayer);
            }
        });
        this.numbersLayer.addTo(this.map);
        this.hexLayer.addTo(this.map);
        this.highlightLayer.addTo(this.map);
        this.measureControl.addTo(this.map);
        return this;
    }
    jumpTo = function(lat, lon) {
        this.map.setView([lat, lon]);
    }
    hotSpotMarker(icon = "fa fa-circle", color = "text-primary", size = 5, text = "") {
        var i = L.divIcon({
            html: '<i class="' + icon + ' ' + color + '">' + text + '</i>',
            iconSize: [size, size],
            className: 'myDivIcon',
            iconAnchor: [size, size],
            opacity: 0.7
        });
        return i;
    }
    loadHexes(bounds = this.map.getBounds()) {
        var ne_lon = bounds._northEast.lng;
        var ne_lat = bounds._northEast.lat;
        var sw_lon = bounds._southWest.lng;
        var sw_lat = bounds._southWest.lat;
        var apiUrl = "https://www.heliumtracker.io/api/hotspots/map/?ne_lon=" + ne_lon + "&ne_lat=" + ne_lat + "&sw_lat=" + sw_lat + "&sw_lon=" + sw_lon;
        var me = this;
        $.get(apiUrl, function(result) {
            result.forEach(function(data) {
                me.addHex(data);
            });
        });
    }
    loadHotspots(bounds = this.map.getBounds()) {
        var ne_lon = bounds._northEast.lng;
        var ne_lat = bounds._northEast.lat;
        var sw_lon = bounds._southWest.lng;
        var sw_lat = bounds._southWest.lat;
        var apiUrl = "https://www.heliumtracker.io/api/hotspots/map/?ne_lon=" + ne_lon + "&ne_lat=" + ne_lat + "&sw_lat=" + sw_lat + "&sw_lon=" + sw_lon;
        var me = this;
        $.get(apiUrl, function(result) {
            result.forEach(function(data) {
                me.addHotspot(data);
            });
        });
    }
    activateHex(hex) {
        this.deactivateHex(this.activeHex);
        this.activeHex = hex;
        // highlight color, select hotspots
        this.loadHotspotsInHex(hex);
        this.map.flyToBounds(this.hexes[hex].poly.getBounds());
        this.clearWitnessMarker();
    }
    deactivateHex(hex) {
        if (hex != undefined) {
            if (this.activeHex == hex) {
                // changes color, remove hotspots
                this.removeHotspotsInHex(hex);
                this.activeHex = undefined;
            }
        }
    }
    loadHotspotsInHex(hex) {
        var me = this;
        this.hexes[hex].hotspots.forEach(function(data) {
            me.addHotspot(data);
        });
    }
    removeHotspotsInHex(hex) {
        var me = this;
        this.hexes[hex].hotspots.forEach(function(data) {
            me.removeHotspot(data);
        });
    }
    addHex(data) {
        if (this.hexes[data.hex] == undefined) {
            var size = "fa-xl text-white";
            var hotspotIcon = "";
            var icon = this.hotSpotMarker(hotspotIcon, size, 1, data.hotspots.length);
            var hex = data.hex;
            const hexCenterCoordinates = h3.h3ToGeo(data.hex);
            var poly = L.polygon(h3.h3ToGeoBoundary(data.hex));
            var hexMarker = L.marker([hexCenterCoordinates[0], hexCenterCoordinates[1]], {
                title: data.name,
                icon: icon
            });
            var me = this;
            poly.on('click', function(e) {
                me.activateHex(hex);
            });
            this.numbersLayer.addLayer(hexMarker);
            //hexMarker.addTo(this.map)
            //poly.addTo(this.map);
            this.hexLayer.addLayer(poly);
            this.hexes[data.hex] = {
                hex: data.hex,
                hotspots: data.hotspots,
                poly: poly
            };
        }
    }
    highlightHex(hex, color = "#fad732") {
        if (this.hexes[hex] != undefined && this.hexes[hex].poly != undefined) {
            this.hexes[hex].poly.setStyle({
                color: color,
                fillOpacity: 0.5
            });
            if (this.hexLayer.hasLayer(this.hexes[hex].poly)) {
                this.hexLayer.removeLayer(this.hexes[hex].poly);
            }
            this.highlightLayer.addLayer(this.hexes[hex].poly);
            this.highlightHexes.push(hex);
        }
    }
    addHotspot(data) {
        if (this.hotspots[data.id] == undefined) {
            var me = this;
            var size = "fa-lg text-success";
            var hotspotIcon = "fa fa-circle";
            if (data.id == this.hotspot_id) {
                size = "fa-lg text-white";
            } else {
                if (data.online != undefined && !data.online) {
                    size = "fa-lg text-danger";
                } else {
                    if (data.syncing) {
                        size = "fa-lg text-warning";
                    }
                }
            }
            if (this.witnesses.includes(data.id)) {
                size = "fa-lg text-yellow";
            }
            var myDate = new Date();
            var newDate = new Date(myDate.getTime() - (60 * 60 * 24 * 14 * 1000));
            if (data.created_at > newDate.toISOString()) {
                icon = "fas fa-plus-cirlce";
            }
            var icon = this.hotSpotMarker(hotspotIcon, size, 2);
            var hotspotMarker = L.marker([data.lat, data.lon], {
                title: data.name,
                iconSize: [8, 8],
                icon: icon
            });
            hotspotMarker.bindPopup("Loading...")
            hotspotMarker.on('click', function(e) {
                var popup = e.target.getPopup();
                $.get("/hotspots/" + data.id + "/preview", {}).done(function(data) {
                    popup.setContent(data);
                    popup.update();
                });
                me.loadWitnesses(data.id, data.lat, data.lon);
            });
            hotspotMarker.addTo(this.map)
            this.hotspots[data.id] = hotspotMarker;
        }
    }
    removeHotspot(data) {
        if (this.hotspots[data.id] != undefined) {
            this.map.removeLayer(this.hotspots[data.id]);
            delete this.hotspots[data.id];
        }
    }
    loadWitnesses(id, lat, lon) {
        this.clearWitnessMarker();
        this.hotspot_id = id;
        var latlngsTotal = [
            [this.lat, this.lon]
        ];
        var apiUrl = "https://www.heliumtracker.io/api/hotspots/" + id + "/witness_data"
        var me = this;
        $.get(apiUrl, function(result) {
            result.forEach(function(data) {
                var distance = calcCrow(me.lat, me.lon, data.lat, data.lon);
                me.witnesses.push(data.id);
                if (distance < 16) {
                    latlngsTotal.push([data.lat, data.lon]);
                }
                var color = "#fad732";
                if (data.is_witnessed == true && data.is_witnessing == true) {
                    color = "#FACDAF";
                } else if (data.is_witnessed == true) {
                    color = "#D26E96";
                }
                me.addWitnessMarker(data, lat, lon, distance * 500);
                setTimeout(function() {
                    me.highlightHex(data.location_hex, color);
                }, distance * 500)
            });
            if (result.length > 0) {
                var polylineTotal = L.polyline(latlngsTotal, {
                    color: 'red'
                }); //.addTo(hotspotMap.map);
                me.map.fitBounds(polylineTotal.getBounds());
                setTimeout(function() {
                    me.loadHexes(polylineTotal.getBounds());
                }, 1500)
            } else {
                me.loadHexes();
            }
        });
    }
    loadBeacon(id, lat, lon) {
        var latlngsTotal = [
            [this.lat, this.lon]
        ];
        var me = this;
        var apiUrl = "https://www.heliumtracker.io/api/beacons/" + id + "/witness_data"
        $.get(apiUrl, function(result) {
            result.forEach(function(data) {
                me.witnesses.push(data.id);
                var distance = calcCrow(me.lat, me.lon, data.lat, data.lon);
                if (distance < 16) {
                    latlngsTotal.push([data.lat, data.lon]);
                }
                //setTimeout(function() {
                me.addWitnessMarker(data, lat, lon, distance * 500);
                setTimeout(function() {
                    me.highlightHex(data.location_hex);
                }, distance * 500)
            });
            if (result.length > 0) {
                var polylineTotal = L.polyline(latlngsTotal, {
                    color: 'red'
                }); //.addTo(hotspotMap.map);
                me.map.fitBounds(polylineTotal.getBounds());
                setTimeout(function() {
                    me.loadHexes(polylineTotal.getBounds());
                }, 1500)
            } else {
                me.loadHexes();
            }
        });
    }
    clearWitnessMarker() {
        var me = this;
        this.witnessMarkers.forEach(function(wit) {
            me.map.removeLayer(wit);
        });
        this.highlightHexes.forEach(function(hex) {
            if (me.hexes[hex] != undefined) {
                me.hexes[hex].poly.setStyle({
                    color: '#3388ff',
                    fillOpacity: 0.2
                });
            }
            me.highlightLayer.removeLayer(me.hexes[hex].poly);
            me.hexLayer.addLayer(me.hexes[hex].poly);
        });
        this.witnessMarkers = Array();
        this.witnesses = Array();
        this.highlightHexes = Array();
    }
    addWitnessMarker(data, lat, lon, duration) {
        //var marker = L.marker([data.lat, data.lon], {title: data.name, icon: towerYellow}).addTo(hotspotMap.map);
        var latlngs = [
            [lat, lon],
            [data.lat, data.lon]
        ];
        var polyline = L.motion.polyline(latlngs, {
            color: '#fad732',
            weight: 1,
            opacity: 0.8
        }, {
            auto: true,
            duration: duration
        }, {
            removeOnEnd: true,
            showMarker: false,
            icon: L.divIcon({
                html: "",
                iconSize: L.point(0.5, 0.5)
            })
        });
        polyline.addTo(this.map);
        this.witnessMarkers.push(polyline);
    }
}