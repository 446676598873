// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import {
    initLoadCSS,
    initCSS
} from './angle/modules/common/load-css';
initCSS();
import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import {
    initNotify
} from './angle/modules/elements/notify';
//import initSweetAlert from './angle/modules/elements/sweetalert';
//import initNestable from './angle/modules/elements/nestable';
//import initSortable from './angle/modules/elements/sortable';
//import initCardDemo from './angle/modules/elements/cards';
import initPortlets from './angle/modules/elements/porlets';
import initSlimsSroll from './angle/modules/common/slimscroll';
import {
    initFlotSpline,
    initFlotBarStacked
} from './angle/modules/charts/flot';
//import initSparkline from './angle/modules/charts/sparkline';
import {
    initPayment,
    initPremiumPayment,
    initQrcode,
    changePlans
} from './payout/payout';
import {
    StateToggler,
    initToggleState
} from './angle/modules/common/toggle-state';
import initBootstrap from './angle/modules/common/bootstrap-start';
// TagsInput
// import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.css';
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.min.js';
require("easy-autocomplete")
import ClipboardJS from 'clipboard/dist/clipboard.min.js';
import 'jquery-datetimepicker';
//import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import HotspotMap from './map/map.js';
$(function() {
    $("#test_sound").click(function(event) {
        document.getElementById("cash-audio").pause();
        document.getElementById("cash-audio").muted = false;
        $('#cash-audio').prop("volume", $('#user_volume').val());
        document.getElementById("cash-audio").play();
    });
});
import "channels"
var jQuery = window.jQuery = window.$ = require("jquery")
Rails.start()
//Turbolinks.start()
ActiveStorage.start()
import "kjua"
//= require turbolinks
//--- Bootstrap
import 'bootstrap';
import appInit from './angle/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);
$(document).ready(function() {
    initBootstrap();
    initToggleState();
    initNotify();
    //initSparkline();
    /*initSweetAlert();
    initNestable();
    initSortable();
    initCardDemo();*/
    initPortlets();
    var $body = $('body');
    new StateToggler().restoreState($body);
    initFlotBarStacked();
    initSlimsSroll();
    if ($("#payout").length == 1) {
        initPayment();
    }
    if ($("#premium_payment").length == 1) {
        initPremiumPayment();
    }
    initQrcode();
    changePlans();
    if ($("#mapid").length == 1) {
        hotspotMap = new HotspotMap('mapid', $("#mapid").data("id"), $("#mapid").data("lat"), $("#mapid").data("lon"), $("#mapid").data("name"));
        hotspotMap.init();
        if ($("#mapid").data("beacon")) {
            hotspotMap.loadBeacon($("#mapid").data("beacon"), $("#mapid").data("lat"), $("#mapid").data("lon"));
        } else {
            hotspotMap.loadWitnesses($("#mapid").data("id"), $("#mapid").data("lat"), $("#mapid").data("lon"));
        }
    }
    var clipboard = new ClipboardJS('.clip');
    clipboard.on('success', function(e) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);
        $.notify(e.text + " copied", {
            showDuration: 1000,
            autoHideDelay: 2000
        });
    });
    var options = {
        url: function(phrase) {
            return "/search/autocomplete.json?q=" + phrase;
        },
        getValue: "name",
        template: {
            type: "links",
            fields: {
                link: "link"
            }
        },
    };
    $('*[data-behavior="autocomplete"]').easyAutocomplete(options);


    var options_address = {
        url: function(phrase) {
            return "/search/autocomplete_address.json?q=" + phrase;
        },
        getValue: "address",
        template: {
            type: 'custom',
            method: function (value, item) {
                return item.name;
            }
        }
        
    };


    $('*[data-behavior="autocompleteaddress"]').easyAutocomplete(options_address);

    $.each($('.datepicker'), function() {
        var mindate = $(this).data('mindate');
        var enddate = $(this).data('maxdate');
        if (mindate) {
            $(this).datetimepicker({
                timepicker: false,
                format: 'Y-m-d',
                minDate: mindate,
                maxDate: enddate // max date is ToDay, current date;
            });
        } else {
            $(this).datetimepicker({
                timepicker: false,
                format: 'Y-m-d'
            });
        }
    });
    $.each($('.datetimepicker'), function() {
        $(this).datetimepicker({
            timepicker: true,
            format: 'Y-m-d H:i'
        });
    });
    var layout = $(document.body).data("layout");
    if (layout == "dark") {
        $("table.table").addClass("table-dark");
    }
    $('.copytext').click(function() {
        var cop = $(this);
        var thistext = $(this).data("text");
        navigator.clipboard.writeText(thistext);
        $.notify("Value copied to clipboard", {
            showDuration: 1000,
            autoHideDelay: 2000
        });
    });
});
$(document).on("activity.ready", function(event) {
    console.log("activity ready");
    initBootstrap();
});
$(document).on("reward.ready", function(event) {
    console.log("reward ready");
    initBootstrap();
    initFlotBarStacked();
});